// src/CallToAction.js
import React from 'react';
import './CallToAction.css';

const CallToAction = () => {
    return (
        <section className="cta">
            <h2 className="cta-title" data-aos="fade-down">Ready to Show Your Appreciation?</h2>
            <p className="cta-subtitle" data-aos="fade-up">Join Decks for Appreciation and start creating cards for public service workers today. Your gratitude can make a big difference.</p>
            <a href="#create" className="cta-button" data-aos="fade-up">Create a Card</a>
        </section>
    );
}

export default CallToAction;
