// src/Hero.js
import React from 'react';
import './Hero.css';

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero-content">
                <h1 className="hero-title" data-aos="fade-down">Welcome to Decks</h1>
                <p className="hero-subtitle" data-aos="fade-up">Our platform helps you send cards of appreciation to public service workers.</p>
                <button className="hero-button" data-aos="fade-up">Get Started</button>
            </div>
        </section>
    );
}

export default Hero;
