// src/Testimonials.js
import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
    return (
        <section className="testimonials">
            <h2 className="testimonials-title" data-aos="fade-down">Testimonials</h2>
            <div className="testimonials-container" data-aos="fade-up">
                <div className="testimonial">
                    <p className="testimonial-text">"Decks for Appreciation has given a lot of cards and has helped out the Katy community efficiently."</p>
                    <h3 className="testimonial-author">- Kenneth Parker (Katy Fire Department)</h3>
                </div>
                <div className="testimonial">
                    <p className="testimonial-text">"A wonderful way to express gratitude to healthcare workers."</p>
                    <h3 className="testimonial-author">- Jane Smith (Memorial Hermann Hospital)</h3>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
