import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import the EmailJS library
import './CreateCard.css';

// List of negative words to filter out from the message
const negativeWords = [
    'bad', 'hate', 'ugly', 'stupid', 'dumb', 'idiot', 'racist', 'slur', 
    'fool', 'dumbass', 'shit', 'fuck', 'bitch', 'asshole', 'bastard', 
    'moron', 'retard', 'scum', 'pig', 'jackass', 'jerk', 'loser', 'lame',
    'pathetic', 'worthless', 'trash', 'garbage', 'horrible', 'awful',
    'terrible', 'disgusting', 'nasty', 'vile', 'abomination', 'cretin',
    'imbecile', 'douche', 'whore', 'slut', 'idiotic', 'inferior', 'scum', 'idiot'
];

const CreateCard = () => {
    // State to manage form data and error messages
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        location: ''
    });
    const [error, setError] = useState('');

    // Function to handle changes in form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        // Check for negative words in the message
        const containsNegativeWords = negativeWords.some(word => formData.message.toLowerCase().includes(word));
        if (containsNegativeWords) {
            setError('Please make your message nicer. It contains words that are not allowed.');
            return;
        }

        // Prepare the data for EmailJS, matching the placeholders in your template
        const emailData = {
            from_name: formData.name,         // Maps to {{from_name}} in the template
            to_name: formData.email,          // Maps to {{to_name}} in the template
            message: formData.message,        // Maps to {{message}} in the template
            email: formData.email,            // Maps to {{email}} in the template
            reply_to: formData.email          // Maps to {{reply_to}} in the template
        };

        // Send the email using EmailJS
        emailjs.send('service_1bf0o4r', 'template_kw0cyj9', emailData, 'hKCbXkb0f7D4VIWnj')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Card submitted successfully!');
            }, (error) => {
                console.error('FAILED...', error);
                alert('Failed to send the card. Please try again later.');
            });

        // Clear the form after submission
        setFormData({
            name: '',
            email: '',
            message: '',
            location: ''
        });
    };

    return (
        <section className="create-card">
            <h1 className="create-card-title" data-aos="fade-down">Create a Card</h1>
            <form className="create-card-form" onSubmit={handleSubmit} data-aos="fade-up">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                        placeholder="First and Last Name"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email of the Public Service Department</label>
                    <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                        placeholder="Enter the recipient's email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input 
                        type="text" 
                        id="location" 
                        name="location" 
                        value={formData.location} 
                        onChange={handleChange} 
                        required 
                        placeholder="Enter the location (e.g., police office, hospital)"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea 
                        id="message" 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required 
                        placeholder="Enter your message here"
                    ></textarea>
                </div>
                {error && <p className="error">{error}</p>}
                <button type="submit" className="submit-button">Send</button>
            </form>
        </section>
    );
}

export default CreateCard;
