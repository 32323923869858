// src/ChildCardDesigns.js
import React, { useState, useEffect } from 'react';
import './ChildCardDesigns.css';

const cardDesigns = [
];

const ChildCardDesigns = () => {
    const [downloads, setDownloads] = useState({});

    useEffect(() => {
        const storedDownloads = JSON.parse(localStorage.getItem('downloads')) || {};
        setDownloads(storedDownloads);
    }, []);

    const handleDownload = (design) => {
        const link = document.createElement('a');
        link.href = design.downloadUrl;
        link.download = design.name;
        link.click();

        // Update download count
        const newDownloads = {
            ...downloads,
            [design.id]: (downloads[design.id] || 0) + 1
        };

        setDownloads(newDownloads);
        localStorage.setItem('downloads', JSON.stringify(newDownloads));
    };

    return (
        <section className="child-card-designs">
            <h1 className="title" data-aos="fade-down">Child Card Designs Coming Soon</h1>
            <div className="designs-container" data-aos="fade-up">
                {cardDesigns.map((design) => (
                    <div className="design-card" key={design.id}>
                        <img src={design.imageUrl} alt={design.name} className="design-image" />
                        <h2 className="design-name">{design.name}</h2>
                        <button 
                            className="download-button" 
                            onClick={() => handleDownload(design)}
                        >
                            Download
                        </button>
                        <p className="download-count">
                            Downloads: {downloads[design.id] || 0}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ChildCardDesigns;
