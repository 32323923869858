// src/ContactUs.js
import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // This is a mock submission. You would typically send the data to your server here.
        console.log('Form submitted:', formData);

        // Send email logic (using an email sending service like EmailJS, or your backend)
        alert('Form submitted. We will contact you soon!');

        // Clear the form
        setFormData({
            name: '',
            email: '',
            message: ''
        });
    };

    return (
        <section className="contact-us">
            <h1 className="contact-us-title" data-aos="fade-down">Start A Chapter</h1>
            <form className="contact-form" onSubmit={handleSubmit} data-aos="fade-up">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea 
                        id="message" 
                        name="message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required 
                    ></textarea>
                </div>
                <button type="submit" className="submit-button">Send</button>
            </form>
        </section>
    );
}

export default ContactUs;
