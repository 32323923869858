// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <ul className="social-links">
                <li><a href="https://instagram.com/decksforappreciation" className="social-link" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            </ul>
            <div className="contact-info">
                <p>Email: decksforappreciation@gmail.com</p>
            </div>
            <div className="copyright">
                <p>&copy; 2024 DecksForAppreciation. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
