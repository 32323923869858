// src/Donate.js
import React from 'react';
import './Donate.css';

const Donate = () => {
    const nonprofits = [
        { name: 'Red Cross', url: 'https://www.redcross.org' },
        // Add other nonprofits here
    ];

    return (
        <section className="donate">
            <h1 className="donate-title" data-aos="fade-down">Donate</h1>
            <p className="donate-description" data-aos="fade-up">Support public service workers by donating to the following organizations:</p>
            <div className="nonprofit-container" data-aos="fade-up">
                <ul className="nonprofit-list">
                    {nonprofits.map((nonprofit, index) => (
                        <li key={index} className="nonprofit-item">
                            <a href={nonprofit.url} target="_blank" rel="noopener noreferrer" className="nonprofit-link">
                                {nonprofit.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default Donate;
