// src/App.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './Header';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CallToAction from './CallToAction';
import Footer from './Footer';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import CreateCard from './CreateCard';
import Donate from './Donate';
import ChildCardDesigns from './ChildCardDesigns';
import './App.css';

const App = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Decks For Appreciation</title>
            </Helmet>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={
                        <>
                            <Hero />
                            <Features />
                            <Testimonials />
                            <CallToAction />
                        </>
                    } />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/create" element={<CreateCard />} />
                    <Route path="/donate" element={<Donate />} />
                    <Route path="/child-card-designs" element={<ChildCardDesigns />} />
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;
