import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="header">
            <div className="header-container">
                <img src="logo192.png" alt="DecksForAppreciation Logo" className="logo" />
                <nav>
                    <ul className={`nav-list ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                        <li><Link to="/" className="nav-link" onClick={toggleMobileMenu}>Home</Link></li>
                        <li><Link to="/about" className="nav-link" onClick={toggleMobileMenu}>About Us</Link></li>
                        <li><Link to="/create" className="nav-link" onClick={toggleMobileMenu}>Create a Card</Link></li>
                        <li><Link to="/donate" className="nav-link" onClick={toggleMobileMenu}>Donate</Link></li>
                        <li><Link to="/contact" className="nav-link" onClick={toggleMobileMenu}>Contact Us</Link></li>
                        <li><Link to="/child-card-designs" className="nav-link" onClick={toggleMobileMenu}>Child Card Designs</Link></li>
                    </ul>
                </nav>
                <div className="hamburger" onClick={toggleMobileMenu}>
                    &#9776;
                </div>
            </div>
        </header>
    );
}

export default Header;
