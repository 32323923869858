// src/Features.js
import React from 'react';
import './Features.css';

const Features = () => {
    return (
        <section className="features">
            <h2 className="features-title" data-aos="fade-down">Our Non-Profit</h2>
            <div className="features-container" data-aos="fade-up">
                <div className="feature">
                    <h3 className="feature-title">Located Nationwide</h3>
                    <p className="feature-description">Decks for Appreciation has locations in Houston, Dallas, Atlanta, Louisville, Philadelphia, and San Jose. We are dedicated to spreading gratitude across the country.</p>
                </div>
                <div className="feature">
                    <h3 className="feature-title">Community Impact</h3>
                    <p className="feature-description">We have written and delivered almost 10,000 cards to public service workers in Houston alone! Our mission is to ensure that every public service worker feels valued and appreciated.</p>
                </div>
                <div className="feature">
                    <h3 className="feature-title">Future Work</h3>
                    <p className="feature-description">We plan to expand further to ensure public service workers across the nation receive the appreciation they deserve. Join us in our mission to spread gratitude.</p>
                </div>
            </div>
        </section>
    );
}

export default Features;
