// src/AboutUs.js
import React from 'react';
import './AboutUs.css';

const teamMembers = [
    { name: 'Nishant Gadde', position: 'Co-President & Cofounder', imageUrl: 'nishant.png' },
    { name: 'Laith Alkubaisi', position: 'Co-President & Cofounder', imageUrl: 'laith.png' },
    { name: 'Shiva Uppaladhini', position: 'Co-President & Cofounder', imageUrl: 'shiva.png' },
    { name: 'Arin Patil', position: 'President of Atlanta & Chief Marketing Officer', imageUrl: 'arin.png' },
    { name: 'Prahas Velagapudi', position: 'President of Dallas', imageUrl: 'prahas.png' },
    { name: 'Ishaani Kusunam', position: 'Vice President', imageUrl: 'ishaani.png' },
    { name: 'Nagashrith Kaliki', position: 'President of San Jose', imageUrl: 'path/to/jessica_lee.jpg' },
    { name: 'Amar Gottumukhala', position: 'Treasurer', imageUrl: 'path/to/jessica_lee.jpg' },
    { name: 'Misha Badrinath', position: 'President of Louisville', imageUrl: 'misha.png' },
    { name: 'Rosie', position: 'Chief of Leadership & Social Media', imageUrl: 'rosie.png' },
];

const AboutUs = () => {
    return (
        <section className="about-us">
            <h1 className="about-us-title" data-aos="fade-down">About Us</h1>
            <div className="team-container" data-aos="fade-up">
                {teamMembers.map((member, index) => (
                    <div className="team-member" key={index}>
                        <img src={member.imageUrl} alt={member.name} className="member-image" />
                        <h2 className="member-name">{member.name}</h2>
                        <p className="member-position">{member.position}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default AboutUs;
